import useIndividualProductData from '../useIndividualProductData'
import { useProductPriceTiers } from '../useProductPriceTiers/useProductPriceTiers'
import { getProductBaseFinalPrice, getProductGuestFallbackPrice, getUserMinimumPrices } from './useProductPrice.helpers'
import type { PricesMinimalProduct } from './useProductPrice.types'

/*
  USER - logged in user
  GUEST - not logged in visitor
  CUSTOMER - term used in pricing - individual data
 */
function useProductPrice() {
  const { isLoggedIn } = useCustomer()
  const { getProductIndividualPrice, getProductIndividualCustomerPrice } = useIndividualProductData()

  const getProductFinalPrice = (product: PricesMinimalProduct, quantity?: number) => {
    if (!product) {
      return 0
    }

    if (typeof quantity !== 'number' || quantity === 0) {
      return getProductBaseFinalPrice(product)
    }

    const { getUnitPriceForQuantity } = useProductPriceTiers(product)
    return getUnitPriceForQuantity(quantity)
  }

  const getProductRegularPrice = (
    product: PricesMinimalProduct,
  ): number => {
    if (!product) {
      return 0
    }

    if (!isLoggedIn.value) {
      return getProductGuestFallbackPrice(product)
    }

    /**
     *  Minimum price regular price:
     *  - get regular price from `getCustomerIndividualProductsData` `product.price_range.minimum_price.regular_price.value` if exists
     *  - if above mentioned price does not exist - use `product.price_range.minimum_price.regular_price.value` from `product`
     */
    const individualProductPrices = getProductIndividualPrice(product)

    const defaultMinimumRegularPrice = product?.price_range?.minimum_price?.regular_price?.value
    const customerMinimumRegularPrice = individualProductPrices?.price_range?.minimum_price?.regular_price?.value

    return customerMinimumRegularPrice || defaultMinimumRegularPrice || 0
  }

  const isOnPromotion = (product: PricesMinimalProduct) => {
    if (!product) {
      return false
    }

    const regularPrice = getProductRegularPrice(product)
    const finalPrice = getProductFinalPrice(product)

    if (typeof regularPrice !== 'number' || typeof finalPrice !== 'number') {
      return false
    }

    if (!isLoggedIn.value) {
      return regularPrice > finalPrice
    }

    const productCustomerPrice = getProductIndividualCustomerPrice(product)?.price
    const individualProductPrices = getProductIndividualPrice(product)

    const { calculatedMinimumRegularPrice, calculatedMinimumFinalPrice } = getUserMinimumPrices(product, individualProductPrices) || {}

    if (typeof productCustomerPrice === 'number') {
      return calculatedMinimumRegularPrice > calculatedMinimumFinalPrice && productCustomerPrice > calculatedMinimumFinalPrice
    }
    return calculatedMinimumRegularPrice > calculatedMinimumFinalPrice
  }

  return {
    getProductRegularPrice,
    getProductFinalPrice,
    isOnPromotion,
  }
}

export { useProductPrice }
